<template>
    <div id="dc-thecontainer" :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]">
        <Header />
        <div class="dc-main">
            <div class="dc-container">
                <router-view />
            </div>
            <sidebar-menu :menu="menu" :collapsed="collapsed" :theme="selectedTheme" :show-one-child="true" @toggle-collapse="onToggleCollapse">
                <div id="dc-sidebar-header" slot="header">
                    <img src="./../assets/logo.png" />
                </div>
            </sidebar-menu>
            <div v-if="isOnMobile && !collapsed" class="sidebar-overlay" @click="collapsed = true" />
        </div>
    </div>
</template>

<script>
import Header from "./other/Header";

export default {
    name: "TheContainer",
    components: {
        Header,
    },
    data() {
        return {
            collapsed: false,
            themes: [
                {
                    name: "Default theme",
                    input: "",
                },
                {
                    name: "White theme",
                    input: "white-theme",
                },
            ],
            selectedTheme: "",
            isOnMobile: false,
            menu: [
                {
                    header: true,
                    title: "Dirt Cheap",
                    hiddenOnCollapse: true,
                },
                {
                    href: "/woocommerce",
                    title: "Online Orders",
                    icon: "fas fa-cart-arrow-down",
                },
                {
                    title: "",
                    icon: "",
                },
                {
                    href: "/dashboard",
                    title: "Dashboard",
                    icon: "fa fa-chart-area",
                },
                {
                    title: "",
                    icon: "",
                },
                {
                    href: "/tickets",
                    title: "Tickets",
                    icon: "fa fa-clipboard-list",
                },
                {
                    href: "/retails",
                    title: "Walkin",
                    icon: "fa fa-money-bill-alt",
                },
                {
                    title: "",
                    icon: "",
                },
                {
                    href: "/customers",
                    title: "Customers",
                    icon: "fa fa-user",
                },
                {
                    href: "/products",
                    title: "Products",
                    icon: "fa fa-columns",
                },
                {
                    title: "",
                    icon: "",
                },
                {
                    href: "/reports",
                    title: "Reports",
                    icon: "fa fa-clipboard-list",
                },
                // {
                //     href: "/sms",
                //     title: "SMS",
                //     icon: "fa fa-sms",
                // },
                {
                    href: "/settings",
                    title: "Settings",
                    icon: "fa fa-sliders-h",
                },
                // DirtCheap - Settings
                // {
                //     href: "/settings-dirtcheap",
                //     title: "Settings DirtCheap",
                //     icon: "fa fa-sliders-h",
                // },
            ],
        };
    },
    methods: {
        onToggleCollapse(collapsed) {
            this.collapsed = collapsed;
        },
        // onItemClick(event, item, node) {
        //     console.log("onItemClick");
        //     console.log(event);
        //     console.log(item);
        //     console.log(node);
        // },
        onResize() {
            if (window.innerWidth <= 767) {
                this.isOnMobile = true;
                this.collapsed = true;
            } else {
                this.isOnMobile = false;
                this.collapsed = false;
            }
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
};
</script>

<style lang="scss">
#dc-thecontainer {
    height: 100%;
    width: 100%;
    padding-left: 225px;
    transition: 0.3s ease;
    background-color: #ebedef;
}
#dc-thecontainer.collapsed {
    padding-left: 50px;
}
#dc-thecontainer.onmobile {
    padding-left: 50px;
}
.v-sidebar-menu {
    background-color: #403529;

    &.vsm_expanded {
        max-width: 225px !important;
    }

    #dc-sidebar-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-right: 1px solid black;
        width: 100%;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            padding: 30px;
            object-fit: contain;
        }
    }
}
.sidebar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 900;
}
.dc-main {
    padding: 20px;
    background-color: #ebedef;
}
.dc-container {
    // margin: 20px;
    background-color: #ebedef;
}
pre {
    font-family: Consolas, monospace;
    color: #000;
    background: #fff;
    border-radius: 2px;
    padding: 15px;
    line-height: 1.5;
    overflow: auto;
}
.router-link-exact-active.active {
    background-color: rgba(30, 30, 33, 0.5);
}
</style>
